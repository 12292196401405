import React, {
    useState,
    useEffect,
    useContext,
    memo,
    forwardRef,
  } from 'react';
  import Row from 'react-bootstrap/Row';
  import Col from 'react-bootstrap/Col';
  import Grid from '@material-ui/core/Grid';
  import Box from '@material-ui/core/Box';
  import Image from 'react-bootstrap/Image';
  import Typography from '@material-ui/core/Typography';
  import IconButton from '@material-ui/core/IconButton';
  import Chart from './Chart';
  import Button from 'react-bootstrap/Button';
  import Modal from 'react-bootstrap/Modal';
  import DataElementShareOptions from '../Data/DataElementShareOptions';
  import DataElementDownloadOptions from '../Data/DataElementDownloadOptions';
  import UserSessionDataService from '../../shared/UserSessionDataService';
  import PENDataService from '../../shared/PENDataService';
  import DataAdapter from '../../shared/DataAdapter';
  import ProcessedDataElement from '../../models/ProcessedDataElement';
  import axios from 'axios';
  import { StoryContext } from './StoryContext';
  import { useSelector } from 'react-redux';
  import store from '../../store';
  import {
    modes,
    dataElementTypes,
    DATA_ELEMENT_TITLE_PLACEHOLDER,
    DATA_ELEMENT_SUBTITLE_PLACEHOLDER,
  } from '../../shared/constants';
  import {
    SESSION_SET,
    // HIDE_EXPORT_CHART,
    // EXPORT_DATA_CLEARED,
  } from '../../actions/types';
  import { scroller } from 'react-scroll';
  import { useHistory } from 'react-router-dom';
  import { trackPromise } from 'react-promise-tracker';
  import ChartHelper from '../../shared/ChartHelper';
  
  /* TAB CONTENT COMPONENT */
  const TabContent = memo(
    forwardRef(({ id, visualizerMode, contentId }, ref) => {
      console.log('[TabContent] is running', id);
  
      let history = useHistory();
  
      /* Acceso a la historia del visualizador */
      const context = useContext(StoryContext);
  
      /**
       * Objeto que se crea a partir del elemento del context, para mantener encapsulados los datos del elemento
       * y pasar datos a los componentes hijos
       */
      const [processedDataElement, setProcessedDataElement] = useState(null);
  
      const selectCookie = (state) => state.cookie;
      const cookie = useSelector(selectCookie);
  
      /* DATA REQUESTS */
      const getElementDataPromise = (indicators, source, regions, years) => {
        return PENDataService.getIndicatorsDataEntries(
          indicators,
          source,
          regions,
          years,
        );
      };
  
      const getElementSourcesPromise = (indicators, source) => {
        return PENDataService.getIndicatorsSourcesByIndicatorsKeys(
          indicators,
          source,
        );
      };
  
      /* DELETE OPTIONS */
      const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  
      const handleCloseDeleteModal = () => setShowConfirmDeleteModal(false);
      const handleShowDeleteModal = () => setShowConfirmDeleteModal(true);
  
      const getConfirmDeleteModal = () => {
        return (
          <Modal
            className="modal-indicator-details alert-modal"
            show={showConfirmDeleteModal}
            onHide={handleCloseDeleteModal}
            centered
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <h2>¿Seguro(a) desea eliminar este gráfico?</h2>
              <p className="text-center">
                Si lo elimina no podrá volver a tener acceso a él y deberá
                graficar de nuevo
              </p>
              <Row>
                <Button
                  variant="danger"
                  onClick={onDeleteDataElementClicked}
                  className="center-in-row"
                >
                  Eliminar
                </Button>
              </Row>
              <Row>
                <Button
                  className="center-in-row"
                  variant="link-like"
                  onClick={handleCloseDeleteModal}
                >
                  cancelar
                </Button>
              </Row>
            </Modal.Body>
          </Modal>
        );
      };
  
      /* DOWNLOAD OPTIONS */
      const [showDownloadOptionsModal, setShowDownloadOptionsModal] = useState(
        false,
      );
  
      const handleCloseDownloadOptionsModal = () => {
        // store.dispatch({
        //   type: HIDE_EXPORT_CHART,
        // });
  
        setShowDownloadOptionsModal(false);
      };
  
      const handleShowDownloadOptionsModal = () => {
        setShowDownloadOptionsModal(true);
      };
  
      const getDownloadOptionsModal = () => {
        return (
          <Modal
            size="lg"
            className="modal-indicator-details"
            show={showDownloadOptionsModal}
            onHide={handleCloseDownloadOptionsModal}
            centered
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <DataElementDownloadOptions elementKey={id} />
            </Modal.Body>
          </Modal>
        );
      };
  
      /* SHARE OPTIONS */
      const [showShareOptionsModal, setShowShareOptionsModal] = useState(false);
  
      const handleCloseShareOptionsModal = () => {
        // store.dispatch({
        //   type: EXPORT_DATA_CLEARED,
        // });
        // store.dispatch({
        //   type: HIDE_EXPORT_CHART,
        // });
        setShowShareOptionsModal(false);
      };
      const handleShowShareOptionsModal = () => setShowShareOptionsModal(true);
  
      const getShareOptionsModal = () => {
        return (
          <Modal
            size="lg"
            className="modal-indicator-details"
            show={showShareOptionsModal}
            onHide={handleCloseShareOptionsModal}
            centered
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <DataElementShareOptions
                elementKey={id}
                publicStoryKey={null}
                ref={ref}
              />
            </Modal.Body>
          </Modal>
        );
      };
  
      /* TITLE */
      const getTitleText = () => {
        return getElement().titleText;
      };
  
      const getFinalTitleText = () => {
        const title = getTitleText();
        if (visualizerMode === modes.EDIT) {
          return !!title
            ? title
            : processedDataElement.indicatorsNames === null
            ? DATA_ELEMENT_TITLE_PLACEHOLDER
            : `[Título: ${processedDataElement.indicatorsNames.join(' y ')}]`;
        } else {
          return !!title
            ? title
            : processedDataElement.indicatorsNames === null
            ? DATA_ELEMENT_TITLE_PLACEHOLDER
            : `${processedDataElement.indicatorsNames.join(' y ')}`;
        }
      };
  
      /* SUBTITLE */
      const getSubtitleText = () => {
        return getElement().subtitleText;
      };
  
      const getFinalSubtitleText = () => {
        const subtitle = getSubtitleText();
        if (visualizerMode === modes.EDIT) {
          return !!subtitle ? subtitle : DATA_ELEMENT_SUBTITLE_PLACEHOLDER;
        } else {
          return subtitle;
        }
      };
  
      const getSubtitleSection = () => {
        if (visualizerMode === modes.EDIT || !!getSubtitleText()) {
          return (
            <Row className="chart-subtitle-container">
              <Col>
                <Typography variant="h5" className="data-element-subtitle">
                  {getFinalSubtitleText()}
                </Typography>
              </Col>
            </Row>
          );
        } else {
          return null;
        }
      };
  
      /* LOGO */
      const getCollection = () => {
        return getElement().collection;
      };
  
      const getLogoFilename = () => {
        //console.log('context', context);
        const collection = getCollection();
        //console.log('collection', collection);
        return collection !== null && typeof collection !== 'undefined'
          ? `logo-collection-${collection}.png`
          : 'logo1.svg';
      };
  
      /* SOURCES */
      const getSourcesText = () => {
        const elementSources = processedDataElement.sources;
        const enumeratedSourcesString = elementSources.join(', ');
        const finalPoint = '.';
        return `PEN con datos de ${enumeratedSourcesString}${finalPoint}`;
      };
  
      const getSourcesSection = () => {
        return (
          <Row className="chart-source-container">
            <Col>
              <p>
                <span className="presalte">Fuente: </span>
                <span>{getSourcesText()}</span>
              </p>
            </Col>
          </Row>
        );
      };
  
      /* OTHER HELPERS */
      const getElement = () => {
        return context.session.getSessionStory().getElementByKey(id);
      };
  
      const elementKeyIsValid = () => {
        return context.session.getSessionStory().elementKeyIsValid(id);
      };
  
      /* HOOKS */
  
      /**
       * Efecto para crear el objeto que encapsula los datos del elemento en el context
       */
      useEffect(() => {
        if (!elementKeyIsValid()) {
          return;
        }
  
        let mounted = true;
  
        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
  
        const currentElement = getElement();
        // console.log('currentElement', currentElement);
  
        const indicators = currentElement.indicators;
        const regions = currentElement.regions;
        const years = currentElement.years;
        const collection = currentElement.collection;
        const chartTypes = currentElement.chartConfiguration.types;
  
        if (indicators.length) {
          trackPromise(
            Promise.all([
              getElementDataPromise(indicators, source, regions, years),
              getElementSourcesPromise(indicators, source),
            ])
              .then((results) => {
                if (mounted) {
                  const elementData = results[0];
                  const elementSources = results[1];
                  const elementIndicatorsNames = DataAdapter.adaptSourcesData(
                    results[0].indicators,
                  );
                  setProcessedDataElement(
                    new ProcessedDataElement(
                      elementData,
                      elementSources,
                      collection,
                      chartTypes,
                      elementIndicatorsNames,
                    ),
                  );
  
     
                }
              })
              .catch((error) => {
                console.log('error', error);
              }),
          );
        }
  
        return function cleanup() {
          mounted = false;
          source.cancel('axios request cancelled');
        };
      }, []);
  
      /* MAIN */
      const getMainElement = () => {
        const chartTypesOptions = getElement().chartConfiguration.types;
        const chartGuidesOption = getElement().chartConfiguration.guides;
        const chartColorsOption = getElement().chartConfiguration.colors;
        const chartLegendOption = getElement().chartConfiguration.legend;
  
        switch (contentId) {
          case dataElementTypes.CHART:
            return (
            <div className="shared-content" ref={ref}>
              <div className="actual-export-content" id="actual-export-content">
                <div className="chart-box">
                  <Row>
                      <Col>
                          <Grid
                              container
                              fluid
                              direction="row"
                              alignContent="center"
                              alignItems="center"
                              justify="flex-start"
                          >
                            <style>
                                {`@media print {
                                  .chart-svg-wrapper{}
                                  .legend-container{}
                                  p{margin: 50px 0}
                                  .chart-box{margin: 50px 0}     
                                  }`}
                              </style>    
                              <Chart        
                                  key={`actual-chart-${id}`}
                                  id={id}
                                  elementData={processedDataElement.data}
                                  chartTypesOptions={chartTypesOptions}
                                  chartGuidesOption={chartGuidesOption}
                                  chartColorsOption={chartColorsOption}
                                  chartLegendOption={chartLegendOption}
                                  chartNestingLevel={processedDataElement.chartNestingLevel}
                                  indicatorsDict={processedDataElement.indicatorsDict}
                                  adaptedData={processedDataElement.adaptedData}
                                  visualizerMode={visualizerMode}
                                  chartNestingLevelGroupingKeys={
                                  processedDataElement.chartNestingLevelGroupingKeys
                                  }
                                  maxNumElems={processedDataElement.maxNumElems}
                                  yearsNestedData={processedDataElement.yearsNestedData}
                                  yearsGroupingKeys={processedDataElement.yearsGroupingKeys}
                                  linesAdaptedData={
                                  processedDataElement.linesBarsData.linesAdaptedData
                                  }
                                  barsAdaptedData={
                                  processedDataElement.linesBarsData.barsAdaptedData
                                  }
                                  subXScaleKeys={processedDataElement.linesBarsData.subXScaleKeys}
                              />
                          
                          </Grid>
                      </Col>
                      
                  </Row>
                  {getSourcesSection()}
                  </div>
                </div>
              </div>
            );

          default:
            return null;
        }
  
        /*
        return (
          <Table
            id={id}
            data={processedDataElement.tableSpecification.data}
            columns={processedDataElement.tableSpecification.columns}
            chartColorsOption={chartColorsOption}
            nestingKeys={processedDataElement.adaptedData.nestedData.map(
              (d) => d.key,
            )}
            chartNestingLevel={processedDataElement.chartNestingLevel}
            key={`actual-table-${id}`}
          />
        );
        */
      };
  
      /* EVENTS HANDLERS */
      const onDeleteDataElementClicked = () => {
        const session = context.session;
        trackPromise(
          UserSessionDataService.deleteStoryElement(cookie, session, id)
            .then((result) => {
              console.log('result', result);
              // Se obtuvieron los datos de la sesión actualizada
              // Se guardan en redux store
              store.dispatch({
                type: SESSION_SET,
                payload: result,
              });
              // Si la eliminación del elemento fue exitosa, no se llama el método para ocultar
              // el modal porque el elemento ya no va a existir en el dom
              // handleCloseDeleteModal();
  
              // Cuando se elimina un elemento de datos, se deben eliminar los tooltips asociados
              // a ese elemento de datos que puedan existir para este punto
              ChartHelper.removeTooltipsDivById(id);
            })
            .catch((error) => {
              console.log('error', error);
              handleCloseDeleteModal();
            }),
        );
      };  
      const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
          ref={ref}
        >
          <Image src={'/img/kebab.svg'} fluid />
        </IconButton>
      ));
  
      /* COMPONENT DEFINITION */
      return (
        <>
          {visualizerMode === modes.EDIT &&
            !!processedDataElement &&
            getShareOptionsModal()}
          {!!processedDataElement && getDownloadOptionsModal()}
          {visualizerMode === modes.EDIT &&
            !!processedDataElement &&
            getConfirmDeleteModal()}
          {/*
           * Se puede crear el contenido principal del elemento una vez que este
           * haya sido procesado
           */}
          {!!processedDataElement && (
     
              <div className="chart-box" id={`chart-${id}`}>
                <Row className="chart-title-container">
                    <Col>
                    <Grid
                    container
                    direction="row"
                    alignContent="center"
                    alignItems="center"
                    justify="space-between"
                    >
                    <Image
                        src={`/img/${getLogoFilename()}`}
                        alt="Logo compendio"
                        fluid
                        className="logo-2-space"
                    />
                    <Box flex="1">
                        <Typography variant="h4" className="data-element-title">
                        {getFinalTitleText()}
                        </Typography>
                    </Box>
                    </Grid>
                    </Col>
                </Row>
                {getSubtitleSection()}
                {getMainElement()}
              </div>
  
          )}
        </>
      );
    }),
  );
  
  export default TabContent;
  