import React, { memo } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Image from 'react-bootstrap/Image';
import Typography from '@material-ui/core/Typography';
import Chart from './Chart';
import {
  modes,
  DATA_ELEMENT_TITLE_PLACEHOLDER,
  DATA_ELEMENT_SUBTITLE_PLACEHOLDER,
} from '../../shared/constants';

const ExportChart = memo(
  ({
    id,
    visualizerMode,
    indicatorsNames,
    collection,
    sources,
    titleText,
    subtitleText,
    chartTypesOptions,
    chartGuidesOption,
    chartColorsOption,
    chartLegendOption,
    elementData,
    chartNestingLevel,
    indicatorsDict,
    adaptedData,
    chartNestingLevelGroupingKeys,
    maxNumElems,
    yearsNestedData,
    yearsGroupingKeys,
    linesAdaptedData,
    barsAdaptedData,
    subXScaleKeys,
  }) => {
    console.log('[ExportChart] is running', id);

    const getFinalTitleText = () => {
      const title = titleText;
      if (visualizerMode === modes.EDIT) {
        return !!title
          ? title
          : indicatorsNames === null
          ? DATA_ELEMENT_TITLE_PLACEHOLDER
          : `[Título: ${indicatorsNames.join(' y ')}]`;
      } else {
        return !!title
          ? title
          : indicatorsNames === null || typeof indicatorsNames === 'undefined'
          ? DATA_ELEMENT_TITLE_PLACEHOLDER
          : `${indicatorsNames.join(' y ')}`;
      }
    };

    const getFinalSubtitleText = () => {
      const subtitle = subtitleText;
      if (visualizerMode === modes.EDIT) {
        return !!subtitle ? subtitle : DATA_ELEMENT_SUBTITLE_PLACEHOLDER;
      } else {
        return subtitle;
      }
    };

    const getSubtitleSection = () => {
      if (visualizerMode === modes.EDIT || !!subtitleText) {
        return (
          <Row className="chart-subtitle-container">
            <Col>
              <Typography variant="h5" className="data-element-subtitle">
                {getFinalSubtitleText()}
              </Typography>
            </Col>
          </Row>
        );
      } else {
        return null;
      }
    };

    const getLogoFilename = () => {
      return collection !== null && typeof collection !== 'undefined'
        ? `logo-collection-${collection}.png`
        : 'logo1.svg';
    };

    const getSourcesText = () => {
      const elementSources = sources;
      const enumeratedSourcesString = elementSources.join(', ');
      const finalPoint = '.';
      return `PEN con datos de ${enumeratedSourcesString}${finalPoint}`;
    };

    const getSourcesSection = () => {
      return (
        <Row className="chart-source-container">
          <Col>
            <p>
              <span className="presalte">Fuente: </span>
              <span>{getSourcesText()}</span>
            </p>
          </Col>
        </Row>
      );
    };

    return (
      <>
        <Row className="chart-title-container">
          <Col>
            <Grid
              container
              direction="row"
              alignContent="center"
              alignItems="center"
              justify="space-between"
            >
              <Image
                src={`/img/${getLogoFilename()}`}
                alt="Logo compendio"
                fluid
                className="logo-2-space"
              />
              <Box flex="1">
                <Typography variant="h4" className="data-element-title">
                  {getFinalTitleText()}
                </Typography>
              </Box>
            </Grid>
          </Col>
        </Row>
        {getSubtitleSection()}
        <Row>
          <Col>
            <Grid
              container
              fluid
              direction="row"
              alignContent="center"
              alignItems="center"
              justify="flex-start"
            >
              <Chart
                id={id}
                elementData={elementData}
                chartTypesOptions={chartTypesOptions}
                chartGuidesOption={chartGuidesOption}
                chartColorsOption={chartColorsOption}
                chartLegendOption={chartLegendOption}
                chartNestingLevel={chartNestingLevel}
                indicatorsDict={indicatorsDict}
                adaptedData={adaptedData}
                visualizerMode={visualizerMode}
                chartNestingLevelGroupingKeys={chartNestingLevelGroupingKeys}
                maxNumElems={maxNumElems}
                yearsNestedData={yearsNestedData}
                yearsGroupingKeys={yearsGroupingKeys}
                linesAdaptedData={linesAdaptedData}
                barsAdaptedData={barsAdaptedData}
                subXScaleKeys={subXScaleKeys}
              />
            </Grid>
          </Col>
        </Row>
        {getSourcesSection()}
      </>
    );
  },
);

export default ExportChart;
